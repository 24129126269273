<template>
	<div class="form" style="max-width: 900px">
		<div class="form__title">
			{{ title }}
		</div>
		<div class="form__subtitle p-pb-4">Here you can order custom materials that are not on stock.</div>

		<div>
			<table>
				<tr>
					<td class="font-bold pr-4">Supplier *</td>
					<td><Dropdown v-model="fields.supplier" :options="supplierOptions" optionLabel="name" placeholder="Supplier..." class="w-full" /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4">SAP *</td>
					<td><InputText type="text" v-model="fields.sapcode" class="w-full" /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4">Description *</td>
					<td><InputText type="text" v-model="fields.description" class="" /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4">Packaging *</td>
					<td>
						<div><Dropdown v-model="fields.packaging" :options="packagingOptions" optionLabel="name" optionValue="name" class="w-full" /></div>
						<div v-if="fields.packaging && fields.packaging == 'Other:'"><InputText type="text" v-model="fields.packaging_other" class="w-full" /></div>
					</td>
				</tr>
				<tr>
					<td class="font-bold pr-4">Volume</td>
					<td><InputNumber :min="0" :max="99" :step="0.1" :mode="'decimal'" :minFractionDigits="1" :maxFracionDigits="1" v-model="fields.weight" class="w-full" placeholder="Weight kg per package" /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4">Quantity *</td>
					<td><InputNumber :min="1" :max="99" v-model="fields.quantityToAdd" class="w-full" autofocus /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4">Urgent</td>
					<td><Checkbox id="binary" v-model="fields.urgent" :binary="true" /></td>
				</tr>
				<tr>
					<td class="font-bold pr-4"></td>
					<td><Button @click="addToCart()" label="Add to cart" icon="pi pi-shopping-cart" iconPos="left" class="w-full" /></td>
				</tr>
			</table>
		</div>
		<div>* required</div>

		<Toast />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "rawmaterials_non_stock",
	data() {
		return {
			title: "Raw Materials - Non stock",
			packagingOptions: [
				{ name: "Can Coated", code: "" },
				{ name: "Can Uncoated", code: "" },
				{ name: "Bucket", code: "" },
				{ name: "Jerrycan - metal", code: "" },
				{ name: "Jerrycan - plastic", code: "" },
				{ name: "Other:", code: "" },
			],
			fields: {
				sapcode: "",
				description: "",
				packaging: "",
				weight: 0,
				quantityToAdd: 0,
				supplier: "",
				urgent: false,
			},
		};
	},
	computed: {
		...mapState({
			supplierOptions: (state) => state.data.suppliers,
		}),
		amount() {
			let amountArray = [];
			for (let count = 0; count < 100; count++) {
				let amount = {};
				amount.name = count;
				amount.code = count;

				amountArray.push(amount);
			}
			return amountArray;
		},
	},
	methods: {
		async submitSupplierOrder() {
			this.fields.packaging = this.fields.packaging.name;
			this.fields.quantity = this.fields.quantity.name;
			this.fields.supplier = this.fields.supplier.id;

			if (this.fields.packaging == "Other:" && this.fields.packaging_other != "") {
				this.fields.packaging = this.fields.packaging_other;
			}

			await this.$store.dispatch("purchaseCustomNonstock", this.fields);
			if (this.$store.isAdmin) {
				this.router.push("/supplierorders");
			} else {
				this.fields = {
					sapcode: "",
					description: "",
					packaging: "",
					packaging_other: "",
					weight: null,
					quantityToAdd: 0,
					supplier: "",
					urgent: false,
				};
			}
		},
		async addToCart() {
			let cartObj = {
				id: null,
				status: "published",

				...this.fields,
			};

			if (this.fields.packaging == "Other:" && (!this.fields.packaging_other || this.fields.packaging_other == "")) {
				this.$toast.add({ severity: "error", summary: "Shoppingcart!", detail: "Custom packaging is required!", life: 3000 });
				return;
			}
			if (this.fields.sapcode && this.fields.supplier && this.fields.description && this.fields.packaging && this.fields.quantityToAdd) {
				await this.$store.dispatch("addToCart", cartObj);

				this.fields = {
					sapcode: "",
					description: "",
					packaging: "",
					packaging_other: "",
					weight: null,
					quantityToAdd: 0,
					supplier: "",
					urgent: false,
				};

				this.$toast.add({ severity: "success", summary: "Shoppingcart!", detail: "Non stock material added to cart!", life: 3000 });
			} else {
				this.$toast.add({ severity: "error", summary: "Shoppingcart!", detail: "Please fill in all required fields!", life: 3000 });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form {
	padding: 30px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__subtitle {
		text-align: left;
		font-size: 20px;
		margin-bottom: 10px;
	}
	&__fields {
		display: flex;
		flex-direction: column;
		max-width: 50%;
		input,
		div {
			margin-bottom: 10px;
		}
	}
}
.p-dropdown {
	text-align: left !important;
}
.p-field-checkbox {
	text-align: left !important;
	align-items: center !important;
	display: flex !important;
	label {
		margin-left: 5px;
	}
}
.p-button {
	width: 100px;
}
</style>
