<template>
	<div v-if="clientOrder && clientOrder.number">
		<div id="clientOrderContainer">
			<div class="flex pb-4">
				<div class="flex-grow-1">
					<h2>{{ clientOrder.number }}</h2>
				</div>
				<div class="flex-grow-0 text-right">
					<h4 class="p-0 m-0 pb-2">{{ clientOrder.datetime | moment("DD MMMM YYYY") }}</h4>
					<div>{{ clientOrder.datetime | moment("HH:mm") }}</div>
				</div>
			</div>

			<div class="pb-4">
				<div class="grid gap-4">
					<div class="col">
						<div class="mb-4">
							<div class="font-bold">{{ clientOrder.name }}</div>
							<div v-if="clientOrder.costplace">Cost place: {{ clientOrder.costplace }}</div>
							<div v-if="clientOrder.employeenumber">Employee number: {{ clientOrder.employeenumber }}</div>
							<div class="">{{ clientOrder.email }}</div>
							<div v-if="clientOrder.emails && clientOrder.emails.length > 0" class="">
								<div v-for="(email, i) in clientOrder.emails" :key="i">{{ email }}</div>
							</div>
							<div v-if="clientOrder.phone"><i class="pi pi-phone"></i> {{ clientOrder.phone }}</div>
						</div>
						<div v-if="clientOrder.comments" class="mb-4"><i class="pi pi-info-circle"></i> {{ clientOrder.comments }}</div>
					</div>

					<div class="col">
						<div class="mb-4" v-if="clientOrder.deliverymethod">{{ clientOrder.deliverymethod.description }}</div>
						<div class="mb-4" v-if="clientOrder.millingroom">Millingroom: {{ clientOrder.millingroom.name }}</div>
					</div>
				</div>
			</div>

			<div class="pb-4 p-datatable" v-if="clientOrder.orderlines && clientOrder.orderlines.length > 0">
				<div class="p-datatable-wrapper">
					<table class="p-datatable-table">
						<thead class="p-datatable-thead">
							<tr>
								<th>Quantity</th>
								<th>Status</th>
								<th>Packaging</th>
								<th>Weight</th>
								<th>Product</th>
							</tr>
						</thead>
						<tbody class="p-datatable-tbody" v-if="clientOrderLines">
							<tr v-for="orderline in clientOrderLines" :key="orderline.id">
								<td>{{ orderline.quantity }}</td>
								<td>{{ orderline.pickStatus }}</td>
								<td>{{ orderline.packaging }}</td>
								<td>
									<span v-if="orderline.weight">{{ parseFloat(orderline.weight).toFixed(2) }} kg</span>
								</td>
								<td>
									<div><i v-if="orderline.urgent" class="pi pi-clock text-primary" v-tooltip.top="'Urgent'"></i> {{ orderline.sapcode }} {{ orderline.description }}</div>
									<div class="text-xs" v-if="orderline.rawmaterial && orderline.rawmaterial.location">Location: {{ orderline.rawmaterial.location }}</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div>
					<div class="mt-2" v-if="orderContainsUrgent(clientOrder)"><i class="pi pi-clock text-primary" v-tooltip.top="'Urgent'"></i> = Urgent</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		clientOrder: Object,
		clientOrderLines: Array,
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		orderContainsUrgent(order) {
			let self = this;

			let returnValue = false;
			if (order && order.orderlines && self.clientOrderLines) {
				self.clientOrderLines.forEach((orderline) => {
					if (orderline.urgent) {
						returnValue = true;
					}
				});
			}
			return returnValue;
		},
	},
	watch: {},
	mounted() {},
};
</script>

<style lang="scss">
#clientOrderContainer {
	padding: 10mm;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//text-align: center;
	color: #2c3e50;
}

.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__footer {
		text-align: left;
	}
}
.p-inputnumber {
	input {
		width: 50px;
	}
}
</style>
