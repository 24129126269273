<template>
	<div v-if="orders && orders.length > 0 && orderlines && orderlines.length > 0">
		<vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="false" :preview-modal="false" :paginate-elements-by-height="1400" :pdf-quality="1.5" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="clientOrders2Pdf" @beforeDownload="beforePDFDownload($event)">
			<section slot="pdf-content">
				<template v-for="(order, index) in orders">
					<section class="pdf-item">
						<ClientOrderDetails :clientOrder="order" :clientOrderLines="getOrderlinesFromDataTable(order.orderlines)"></ClientOrderDetails>
					</section>
					<div class="html2pdf__page-break" />
				</template>
			</section>
		</vue-html2pdf>
	</div>
</template>

<script>
import ClientOrderDetails from "@/components/ClientOrderDetails.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
	components: { ClientOrderDetails, VueHtml2pdf },
	props: {
		orders: Array,
		orderlines: Array,
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		getOrderlinesFromDataTable(orderlines) {
			let self = this;

			let orderlinesFromDataTable = null;
			let orderlineFromDataTable = null;
			if (orderlines && orderlines.length > 0) {
				orderlinesFromDataTable = [];
				orderlines.forEach((orderline) => {
					orderlineFromDataTable = _.find(self.orderlines, {
						id: orderline.id,
					});

					if (orderlineFromDataTable) {
						orderlinesFromDataTable.push(orderlineFromDataTable);
					}
				});
			}
			return orderlinesFromDataTable;
		},
		async printClientOrders() {
			await this.$refs.clientOrders2Pdf.generatePdf();
			this.$emit("close");
		},
		async beforePDFDownload({ html2pdf, options, pdfContent }) {
			let self = this;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(10);
						pdf.setTextColor(150);
						pdf.text("Page " + i + " of " + totalPages, pdf.internal.pageSize.getWidth() * 0.88, pdf.internal.pageSize.getHeight() - 0.3);
					}
					let pdfOutputBlob = pdf.output("blob");
					let pdfUrl = URL.createObjectURL(pdfOutputBlob);

					// Create a download link and add it to the DOM
					const downloadLink = document.createElement("a");
					downloadLink.href = pdfUrl;
					downloadLink.download = "rawmaterial_orders.pdf";
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);
				});
		},
	},
	watch: {},
	async mounted() {
		await this.printClientOrders();
	},
};
</script>
