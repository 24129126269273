<template>
	<div class="table">
		<div class="table__title">
			{{ title }}
		</div>

		<PurchaseOrderDialog :show="showPurchaseOrderDialog" @hide="showPurchaseOrderDialog = false" :orderline="null" :rawmaterial="selectedMaterial" position="top" :modal="true" />

		<DataTable :paginator="true" :rows="20" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 20, 50]" class="p-datatable-sm p-datatable-striped boxshadow" :value="rawmaterialsCritical" showGridlines :filters.sync="filters" filterDisplay="row">
			<template #header>
				<div class="flex justify-content-between align-items-center">
					<div class="flex-grow-0 pl-4">
						<Button type="button" icon="pi pi-file-excel" class="p-button-success p-button-outlined" @click="exportToExcel" />
					</div>
					<div class="flex-grow-1"></div>
				</div>
			</template>
			<Column field="sapcode" header="SAP" key="sapcode" sortable>
				<template #body="{ data }">
					{{ data.sapcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="champcode" header="Champ" key="champcode" sortable>
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="name" header="Name" key="name" sortable>
				<template #body="{ data }">
					{{ data.name }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="ghs" header="GHS" key="ghs">
				<template #body="{ data }">
					<img v-for="ghs in data.ghs" :key="ghs.id" :src="getGHSImageUrl(ghs)" />
				</template>
			</Column>
			<Column field="packaging" header="Packaging" key="packaging" sortable> </Column>
			<Column field="weight" header="Weight kg per package" key="weight" sortable>
				<template #body="{ data }">
					<div v-if="data.weight">{{ parseFloat(data.weight).toFixed(2) }} kg</div>
				</template>
			</Column>

			<Column v-if="checkPermission('stock_read')" field="stockSum" header="On stock" sortable>
				<template #body="{ data }">
					{{ data.stockSum }}
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stock_min" header="Min stock" key="stock_min" sortable> </Column>
			<Column v-if="checkPermission('stock_read')" field="stock_max" header="Max stock" key="stock_max" sortable> </Column>
			<Column field="location" header="Location Warehouse" key="location" sortable>
				<template #body="{ data }">
					{{ data.location }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>

			<Column field="supplier" header="Order from" key="orderfrom" sortable>
				<template #body="{ data }">
					<div v-if="suppliers.find((o) => o.id === data.supplier)">
						{{ suppliers.find((o) => o.id === data.supplier).code }}
					</div>
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stockSum" header="Stock" sortable>
				<template #body="{ data }">
					<div @click="orderAtSupplier(data)" :class="checkPermission('stock_write') ? 'cursor-pointer' : ''">
						<Badge v-if="data.stockSum <= 0" value="Out of stock" severity="danger" />
						<Badge v-else-if="data.stockSum <= data.stock_min" value="Critical stock" severity="warning" />
						<Badge v-else-if="data.stockSum > data.stock_min" value="On stock" severity="success" />
					</div>
				</template>
			</Column>
			<Column field="purchases" header="Purchased">
				<template #body="{ data }">
					<div v-if="data.purchases">
						<div v-for="(purchase, pi) in data.purchases" :key="pi" style="white-space: nowrap">
							<span v-if="purchase.date" class="text-xs">{{ purchase.date | moment("DD MMMM YYYY") }}: </span>{{ purchase.quantity }}
						</div>
					</div>
				</template>
			</Column>

			<template #footer>
				<div class="table__footer"><strong>For questions please contact:</strong> RDA Warehouse | <strong>E:</strong> <a href="mailto:SR0734@ppg.com"> SR0734@ppg.com</a> |<strong> P:</strong> +31 20 407 53 12</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
import { directus } from "../store";
import { mapState } from "vuex";

import PurchaseOrderDialog from "@/components/PurchaseOrderDialog.vue";

import { FilterMatchMode, FilterOperator } from "primevue/api/";

import * as XLSX from "xlsx";

export default {
	name: "rawmaterials",
	components: { PurchaseOrderDialog },
	data() {
		return {
			title: "Critical stock",
			filters: {
				sapcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				champcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				name: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				location: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
			},
			showAddToCartDialog: false,
			showPurchaseOrderDialog: false,
			selectedMaterial: {
				name: "",
				sap: "",
				packaging: "",
				stock: [
					{
						quantity: 0,
					},
				],
			},
			quantityInput: 1,
			quantityValid: false,
			urgent: false,
		};
	},
	created() {},
	async mounted() {
		await this.getData();
	},
	methods: {
		getData() {
			this.$store.dispatch("getData");
		},
		getGHSImageUrl(ghs) {
			try {
				return this.$store.state.DIRECTUS_URL_ASSETS + ghs.GHS_id.image.id + "?access_token=" + directus.auth.token + "&fit=cover&width=25&height=25&quality=100";
			} catch {
				this.$store.dispatch("logout");
			}
		},

		async orderAtSupplier(payload) {
			if (this.checkPermission("purchase_write")) {
				this.selectedMaterial = JSON.parse(JSON.stringify(payload));
				this.showPurchaseOrderDialog = true;
			}
		},

		exportToExcel() {
			let self = this;

			let excelData = [];

			if (self.rawmaterialsCritical && self.rawmaterialsCritical.length > 0) {
				let materials = self.rawmaterialsCritical;
				materials.forEach((material) => {
					let status = null;
					if (material.stockSum <= 0) {
						status = "Out of stock";
					} else if (material.stockSum <= material.stock_min) {
						status = "Critical stock";
					} else if (material.stockSum > material.stock_min) {
						status = "On stock";
					}

					excelData.push({
						sap: material.sapcode,
						champ: material.champcode,
						description: material.name,
						packaging: material.packaging,
						weight: material.weight ? parseFloat(material.weight).toFixed(2) : null,
						stock: self.checkPermission("stock_read") ? material.stockSum : null,
						MinStock: self.checkPermission("stock_read") ? material.stock_min : null,
						MaxStock: self.checkPermission("stock_read") ? material.stock_max : null,
						location: material.location,
						supplier: material.supplier,
						status: self.checkPermission("stock_read") ? status : null,
					});
				});

				// Convert data to worksheet
				const worksheet = XLSX.utils.json_to_sheet(excelData);

				// Create a new workbook
				const workbook = XLSX.utils.book_new();

				// Append the worksheet to the workbook
				XLSX.utils.book_append_sheet(workbook, worksheet, "CriticalStock");

				// Generate an Excel file and trigger the download
				XLSX.writeFile(workbook, "CriticalStock.xlsx");
			}
		},
	},
	computed: {
		...mapState({
			rawmaterials: (state) => state.data.rawmaterials,
			suppliers: (state) => state.data.suppliers,
			cart: (state) => state.cart,
			purchases: (state) => state.data.purchases,
			rawmaterialsCritical(state) {
				let self = this;
				let rawmaterialsCritical = state.data.rawmaterials;
				rawmaterialsCritical = _.filter(rawmaterialsCritical, function (rawmaterial) {
					return rawmaterial.stockSum <= rawmaterial.stock_min;
				});
				if (self.purchases && self.purchases.length > 0 && rawmaterialsCritical && rawmaterialsCritical.length > 0) {
					rawmaterialsCritical.forEach((material) => {
						let purchases = _.filter(self.purchases, function (purchase) {
							let returnval = false;
							if (purchase && purchase.rawmaterial && purchase.rawmaterial.id && purchase.rawmaterial.id == material.id) {
								let quantity = purchase.quantity ? purchase.quantity : 0;
								let quantity_received = purchase.quantity_received ? purchase.quantity_received : 0;
								if (quantity - quantity_received > 0) {
									returnval = true;
								}
							}
							return returnval;
						});
						material.purchases = purchases;
					});
				}

				return rawmaterialsCritical;
			},
		}),
	},
};
</script>

<style lang="scss">
.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__dialog {
		text-align: left;
	}
	&__footer {
		text-align: left;
	}
}
</style>
