<template>
	<div class="table">
		<div class="table__title">{{ title }}</div>

		<DataTable
			v-if="filters"
			:paginator="true"
			:rows="50"
			responsiveLayout="scroll"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
			:rowsPerPageOptions="[10, 20, 50, 100]"
			class="p-datatable-sm p-datatable-striped datatablebug boxshadow"
			:value="orderlines"
			:groupRowsBy="groupRowsBy"
			rowGroupMode="subheader"
			sortMode="single"
			sortField="orderNumber"
			:sortOrder="-1"
			:expandableRowGroups="false"
			:expandedRowGroups.sync="expandedRowGroups"
			:expandedRows.sync="expandedRows"
			dataKey="id"
			@row-expand="onRowExpand($event)"
			colspan="14"
			showGridlines
			:filters.sync="filters"
			:globalFilterFields="['orderNumber', 'number', 'rawmaterial.sapcode', 'rawmaterial.name', 'pickStatus', 'description', 'order.comments', 'order.costplace', 'packaging', 'supplier.code']"
		>
			<template #header>
				<div class="flex justify-content-between align-items-center">
					<div class="flex-grow-1"></div>

					<div class="flex-grow-0">
						<span style="cursor: pointer" @click="setPickStatusFilter('Fulfilled')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'Fulfilled' ? 1 : 0.3 }" class="mr-2" value="Fulfilled" severity="success" />
						</span>
						<span style="cursor: pointer" @click="setPickStatusFilter('Partially picked')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'Partially picked' ? 1 : 0.3 }" class="mr-2" value="Partially picked" severity="warning" />
						</span>
						<span style="cursor: pointer" @click="setPickStatusFilter('New')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'New' ? 1 : 0.3 }" class="mr-2" value="New" severity="danger" />
						</span>
					</div>

					<div class="flex-grow-0 pr-3">
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters['global'].value" placeholder="Keyword Search" />
						</span>
					</div>
					<div class="flex-grow-0 pr-3"><Button type="button" icon="pi pi-filter-slash" class="p-button-outlined" @click="clearFilters()" /></div>
				</div>
			</template>
			<Column field="orderNumber" header="Order" sortable>
				<template #body="{ data }">
					<div style="text-align: center">
						<h4>{{ data.orderNumber }}</h4>
					</div>
				</template>
			</Column>

			<Column field="number" header="#" sortable>
				<template #body="{ data }">
					<div style="text-align: center">
						<h4>{{ data.number }} <i v-if="data.urgent" class="pi pi-clock text-primary" v-tooltip.top="'Urgent'"></i></h4>
					</div>
				</template>
			</Column>

			<Column field="user_created" header="User" key="user" sortable>
				<template #body="{ data }"> {{ data.user_created.first_name }} {{ data.user_created.last_name }} </template>
			</Column>

			<Column field="order.datetime" header="Order date" sortable>
				<template #body="{ data }">
					<div v-if="data.order">{{ data.order.datetime | moment("DD MMMM YYYY") }}</div>
				</template>
			</Column>
			<Column field="sapcode" header="SAP" sortable>
				<template #body="{ data }">
					{{ data.sapcode }}
				</template>
			</Column>
			<Column field="champcode" header="Champ" sortable>
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
			</Column>
			<Column field="description" header="Description" key="description" sortable>
				<template #body="{ data }"> {{ data.description }} </template>
			</Column>
			<Column field="packaging" header="Packaging" sortable>
				<template #body="{ data }"> {{ data.packaging }} </template>
			</Column>
			<Column field="weight" header="Weight" sortable>
				<template #body="{ data }">
					<div v-if="data.weight">{{ parseFloat(data.weight).toFixed(2) }} kg</div>
				</template>
			</Column>

			<Column field="supplier.code" header="Order from" key="orderfrom" sortable>
				<template #body="{ data }">
					{{ data.supplier.code }}
				</template>
			</Column>
			<Column field="data.rawmaterial.millingrooms" header="Millingrooms">
				<template #body="{ data }">
					<div v-if="data.rawmaterial">
						<span v-for="(millingroom, i) in data.rawmaterial.millingrooms" :key="i" style="font-size: 0.75rem" class="mr-2">{{ millingroom.millingrooms_id.name }}</span>
					</div>
				</template>
			</Column>
			<Column field="stockSum" header="On stock">
				<template #body="{ data }">
					<div v-if="data.rawmaterial">{{ data.stockSum }}</div>
				</template>
			</Column>
			<Column field="quantity" header="Quantity" key="quantity"> </Column>

			<Column field="deliveriesSum" header="Delivered">
				<template #body="{ data }">
					{{ data.deliveriesSum }}
				</template>
			</Column>

			<Column field="toDeliver" header="Remaining">
				<template #body="{ data }">
					{{ data.toDeliver }}
				</template>
			</Column>

			<Column field="pickStatus" header="Status" sortable>
				<template #body="{ data }">
					<Badge v-if="data.pickStatus == 'Fulfilled'" :value="data.pickStatus" severity="success" />
					<Badge v-else-if="data.pickStatus == 'Partially picked'" :value="data.pickStatus" severity="warning" />
					<Badge v-else :value="data.pickStatus" severity="danger" />
				</template>
			</Column>

			<template #groupheader="{ data }">
				<div class="-m-2 p-4 bg-gray-100">
					<div class="mb-2">
						<strong class="text-lg" :class="data.pickStatus == 'Fulfilled' ? 'text-green-500' : 'text-black'">{{ data.orderNumber }}</strong>
					</div>
					<div class="mb-2">
						<Badge v-if="data.order && data.order.deliverymethod && data.order.deliverymethod.name == 'Millingroom' && data.order.millingroom" :value="data.order.deliverymethod.name + ' ' + data.order.millingroom.name" class="mr-2" severity="warning" />
						<Badge v-if="data.order && data.order.deliverymethod && data.order.deliverymethod.name == 'Collect'" :value="data.order.deliverymethod.name" class="mr-2" severity="info" />
					</div>
					<div class="mb-2 text-blue-500" v-if="data.order && data.order.costplace">
						<span class="text-sm">Costplace: {{ data.order.costplace }}</span>
					</div>
					<div class="mb-2 text-blue-500" v-if="data.order && data.order.comments">
						<i class="pi pi-info-circle"></i> <span class="text-sm">{{ data.order.comments }}</span>
					</div>
				</div>
			</template>
		</DataTable>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";

import PurchaseOrderDialog from "@/components/PurchaseOrderDialog.vue";

import VueHtml2pdf from "vue-html2pdf";

export default {
	components: { PurchaseOrderDialog, VueHtml2pdf },
	data() {
		return {
			title: "My orders",
			expandedRowGroups: null,
			expandedRows: null,
			showClientOrderDialog: false,
			showOrderDialog: false,
			showUseStockDialog: false,
			showDeliverToClientDialog: false,
			currentClientOrder: null,
			selectedOrderLine: {
				number: "",
				date_created: "",
				user_created: {
					first_name: "",
					last_name: "",
					email: "",
				},
				rawmaterial: {
					stock: [],
				},
			},
			selectedOrder: {
				number: "",
				date_created: "",
				user_created: {
					first_name: "",
					last_name: "",
					email: "",
				},
				order: {
					orderlines: {
						rawmaterials: {
							stock: {
								quantity: Number,
							},
						},
					},
				},
			},
			clientQuantity: 0,
			filters: null,
			calendarDate: null,
			groupRowsBy: "orderNumber",
		};
	},
	async mounted() {
		this.clearFilters();
		await this.getData();
		this.expandedRowGroups = this.orders.map((order) => order.number);
	},
	methods: {
		getData() {
			this.$store.dispatch("getData");
		},
		showClientOrder(order) {
			let self = this;
			self.currentClientOrder = JSON.parse(JSON.stringify(order));
			self.showClientOrderDialog = true;
		},
		printClientOrder() {
			this.$refs.clientOrder2Pdf.generatePdf();
		},
		onRowExpand(item) {
			this.selectedOrderLine = item.data;
			this.expandedRows = [];
			this.expandedRows.push(item.data);
		},

		getOrderlinesFromDataTable(orderlines) {
			let self = this;

			let orderlinesFromDataTable = null;
			let orderlineFromDataTable = null;
			if (orderlines && orderlines.length > 0) {
				orderlinesFromDataTable = [];
				orderlines.forEach((orderline) => {
					orderlineFromDataTable = _.find(self.orderlines, {
						id: orderline.id,
					});

					if (orderlineFromDataTable) {
						orderlinesFromDataTable.push(orderlineFromDataTable);
					}
				});
			}
			return orderlinesFromDataTable;
		},
		clearFilters() {
			this.filters = JSON.parse(JSON.stringify(this.filtersDefault));
		},
		setPickStatusFilter(pickStatus) {
			let self = this;
			let filters = JSON.parse(JSON.stringify(self.filters));
			if (filters.pickStatus && filters.pickStatus && filters.pickStatus.value == pickStatus) {
				delete filters.pickStatus;
			} else {
				filters.pickStatus = {
					value: pickStatus,
				};
			}

			self.filters = filters;
		},

		getPurchasedQuantity(purchases) {
			return _.sumBy(purchases, function (purchase) {
				if (!purchase.quantity) {
					purchase.quantity = 0;
				}
				if (!purchase.quantity_received) {
					purchase.quantity_received = 0;
				}
				if (purchase.quantity > purchase.quantity_received) {
					return purchase.quantity;
				} else {
					return 0;
				}
			});
		},
		filteredAvailableStock(stock) {
			return _.filter(stock, function (st) {
				return st.stockNetto > 0;
			});
		},
		filterPurchasesForOrderline(purchases) {
			if (purchases) {
				purchases = _.filter(purchases, function (purchase) {
					if (purchase) {
						let quantity = purchase.quantity ? purchase.quantity : 0;
						let quantity_received = purchase.quantity_received ? purchase.quantity_received : 0;
						if (quantity - quantity_received > 0) {
							return true;
						}
					}
				});
			}
			return purchases;
		},
		orderContainsUrgent(order) {
			let self = this;

			let returnValue = false;
			if (order && order.orderlines && self.getOrderlinesFromDataTable(order.orderlines)) {
				self.getOrderlinesFromDataTable(order.orderlines).forEach((orderline) => {
					if (orderline.urgent) {
						returnValue = true;
					}
				});
			}
			return returnValue;
		},

		async beforePDFDownload({ html2pdf, options, pdfContent }) {
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(10);
						pdf.setTextColor(150);
						pdf.text("Page " + i + " of " + totalPages, pdf.internal.pageSize.getWidth() * 0.88, pdf.internal.pageSize.getHeight() - 0.3);
					}
				})
				.save();
		},
	},
	computed: {
		...mapState({
			orders: (state) => state.orders,
			orderlines: (state) => state.orderlines,
			rawmaterials: (state) => state.data.rawmaterials,
			deliveries: (state) => state.deliveries,
			purchases: (state) => state.data.purchases,
			pickTotal() {
				let self = this;
				let sum = 0;
				if (self.selectedOrderLine && self.selectedOrderLine.rawmaterial && self.selectedOrderLine.rawmaterial.stock && self.selectedOrderLine.rawmaterial.stock.length > 0) {
					self.selectedOrderLine.rawmaterial.stock.forEach((item) => {
						if (item.pickQuantity) {
							sum = sum + item.pickQuantity;
						}
					});
				}
				return sum;
			},

			filtersDefault() {
				return {
					global: {
						value: null,
					},
				};
			},
		}),
	},
	watch: {},
};
</script>

<style lang="scss">
#clientOrderContainer {
	padding: 10mm;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//text-align: center;
	color: #2c3e50;
}

.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__footer {
		text-align: left;
	}
}
.p-inputnumber {
	input {
		width: 50px;
	}
}
</style>
