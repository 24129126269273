<template>
	<div class="login__wrapper">
		<img src="../assets/logo.png" alt="PPG logo" class="login__logo" />
		<div class="login boxshadow" v-if="mode == 'login'">
			<div class="login__title"><i class="login__title--icon pi pi-sign-in"></i>LOGIN</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-user"></i>
				</span>
				<InputText id="Email" type="text" v-model="email" placeholder="Email" />
			</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-lock"></i>
				</span>
				<InputText id="Password" type="password" v-model="password" placeholder="Password" />
			</div>
			<div class="login__buttons">
				<Button class="login__button" label="Login" @click="submitLogin" />
			</div>
			<div>
				<div class="flex w-full my-2">
					<div class="flex-grow-1"><a href="javascript:;" @click="setMode('forgot')">Forgot password?</a></div>
					<div class="flex-shrink"><a href="javascript:;" @click="setMode('request')">Request account</a></div>
				</div>
			</div>
		</div>
		<div class="login boxshadow" v-if="mode == 'forgot'">
			<div class="login__title"><i class="login__title--icon pi pi-question-circle"></i>FORGOT PASSWORD</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-user"></i>
				</span>
				<InputText id="Email" type="text" v-model="email" placeholder="Email" />
			</div>

			<div class="login__buttons">
				<Button class="login__button" label="Send" @click="passwordRequest" />
			</div>
			<div>
				<div class="flex w-full my-2">
					<a href="javascript:;" @click="setMode('login')">Log in</a>
				</div>
			</div>
		</div>
		<div class="login" v-if="mode == 'request'">
			<div class="login__title"><i class="login__title--icon pi pi-user"></i>REQUEST ACCOUNT</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon"> F </span>
				<InputText id="First name" type="text" v-model="register.first_name" placeholder="First name" />
			</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon"> L </span>
				<InputText id="Last name" type="Last name" v-model="register.last_name" placeholder="Last name" />
			</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-at"></i>
				</span>
				<InputText id="Email" type="text" v-model="register.email" placeholder="Email" />
			</div>
			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-lock"></i>
				</span>
				<InputText id="Password" type="password" v-model="register.password" placeholder="Password" />
			</div>

			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-lock"></i>
				</span>
				<InputText id="Password2" type="password" v-model="register.password2" placeholder="Repeat" />
			</div>
			<div class="login__buttons">
				<Button class="login__button" label="Submit" @click="requestAccount" />
			</div>
			<div>
				<div class="flex w-full my-2">
					<a href="javascript:;" @click="setMode('login')">Back to log in</a>
				</div>
			</div>
		</div>
		<div class="login" v-if="mode == 'request_ready'">
			<div class="login__title"><i class="login__title--icon pi pi-user"></i>REQUEST ACCOUNT</div>
			<div>Your request is sent. Our staff will get back to you shortly.</div>
		</div>
		<Toast />
	</div>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			email: "",
			password: "",
			mode: "login",
			register: {
				status: "draft",
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				password2: "",
			},
		};
	},
	methods: {
		setMode(mode) {
			this.mode = mode;
		},
		submitLogin() {
			this.$store.dispatch("login", { email: this.email, password: this.password });
			// this.$store.dispatch('login', {email: 'hallo@yourio.nl', password: 'PPG123!'})
		},
		async passwordRequest() {
			let response = await this.$store.dispatch("passwordRequest", this.email);

			if (response && response == "success") {
				this.$toast.add({
					severity: "success",
					summary: "Delivery!",
					detail: "We sent you an e-mail with a reset link.",
					life: 5000,
				});
				return;
			} else {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Something went wrong.",
					life: 5000,
				});
			}
		},
		async requestAccount() {
			if (!this.register.first_name || this.register.first_name == "" || !this.register.last_name || this.register.last_name == "" || !this.register.email || this.register.email == "") {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Please fill in all fields.",
					life: 5000,
				});
				return;
			}
			if (!this.register.password) {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Please enter your password.",
					life: 5000,
				});
				return;
			}
			if (this.register.password && this.register.password !== this.register.password2) {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Passwords do not match.",
					life: 5000,
				});
				return;
			}

			let response = await this.$store.dispatch("createUser", this.register);

			if (response && response.data && response.data.id) {
				await this.$axios.post(this.$store.state.DIRECTUS_URL + "mail/usercreated", {
					//to: [{ Email: "zomerstorm@proton.me" }],
					to: [{ Email: "AMS-MB.R&DWarehouse@ppg.com" }],
					bcc: [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }],
					//to: [{ Email: "zomerstorm@proton.me" }],
					subject: "Raw materials account request",
					variables: {
						user: response.data,
					},
				});

				this.$toast.add({
					severity: "success",
					summary: "Success!",
					detail: "Your request is sent. Our staff will get back to you shortly.",
					life: 50000,
				});
				this.mode = "request_ready";
			} else if (response && response.errors && response.errors.length > 0 && response.errors[0].message) {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: response.errors[0].message,
					life: 5000,
				});
			} else {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: response,
					life: 5000,
				});
			}
		},
	},
	watch: {
		email: {
			handler: function (newEmail, oldEmail) {
				if (newEmail) {
					this.email = newEmail.toLowerCase();
				}
			},
		},
	},
};
</script>

<style lang="scss">
.login__wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login {
	background: white;
	text-align: left;
	padding: 20px;
	border-radius: 8px;
	width: 500px;
	margin: 20px;

	&__title {
		margin: 10px 0 40px;
		font-size: 20px;
		font-weight: bold;

		&--icon {
			margin-right: 10px;
		}
	}

	&__input {
		margin-bottom: 10px;
		width: 100%;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
	}

	&__button {
		margin-bottom: 10px;
	}

	&__link {
		display: block;
		margin-top: 20px;

		&:focus {
			box-shadow: none !important;
		}
	}

	&__logo {
		position: absolute;
		left: 20px;
		top: 30px;
		height: 50px;
	}
}
</style>
