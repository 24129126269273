<template>
	<div>
		<div id="nav" class="nav" v-if="showNavigation !== 'Login'">
			<div class="nav__left">
				<img src="@/assets/logo.png" alt="PPG logo" class="nav__logo" />

				<MenuBar ref="leftMenu" style="padding: 0px" class="mr-4" :model="leftMenuItems" />
			</div>
			<div class="nav__right">
				<MenuBar ref="rightMenu" style="padding: 0px" class="mr-4" :model="rightMenuItems" />

				<Button @click="$router.push('/cart')"><i class="pi pi-shopping-cart nav__link-icon"></i>My Order<Badge v-if="cart.length" :value="cart.length ? cart.length : 0" class="ml-1"></Badge></Button>
			</div>
		</div>
		<router-view />
		<div v-if="1 == 2">
			<div class="table__footer"><strong>For questions please contact:</strong> RDA Warehouse | <strong>E:</strong> <a href="mailto:SR0734@ppg.com"> SR0734@ppg.com</a> |<strong> P:</strong> +31 20 407 53 12</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "LayoutDefault",

	data() {
		return {};
	},
	mounted() {
		//this.$toast.add({severity:'success', summary: 'Success Message', detail:'Order submitted', life: 3000});
	},
	computed: {
		showNavigation() {
			return this.$route.name;
		},
		...mapState({
			cart: (state) => state.cart,
			user: (state) => state.user,
		}),
		fullName() {
			return this.user.first_name + " " + this.user.last_name;
		},
		rightMenuItems() {
			return [
				{
					label: this.fullName,
					icon: "pi pi-fw pi-user",
					items: [
						//{
						//	label: "Account",
						//	command: () => {
						//		this.$router.push("/account");
						//	},
						//},
						{
							label: "Logout",
							command: () => {
								this.$store.dispatch("logout");
							},
						},
					],
				},
			];
		},
		leftMenuItems() {
			let items = [
				{
					label: "Menu",
					items: [
						{
							label: "Raw Materials",
							command: () => {
								this.$router.push("/");
							},
						},
						{
							label: "Raw Materials - Non stock",
							command: () => {
								this.$router.push("/rawmaterialsnonstock");
							},
						},
					],
				},
			];
			if (this.checkPermission("orders_write")) {
				items[0].items.push({
					label: "Orders",
					command: () => {
						this.$router.push("/clientorders");
					},
				});
			} else {
				items[0].items.push({
					label: "Orders overview",
					command: () => {
						this.$router.push("/orders");
					},
				});
			}
			if (this.checkPermission("purchase_write")) {
				items[0].items.push({
					label: "Purchase orders",
					command: () => {
						this.$router.push("/supplierorders");
					},
				});
			}
			if (this.checkPermission("stock_write")) {
				items[0].items.push({
					label: "Critical stock",
					command: () => {
						this.$router.push("/criticalstock");
					},
				});
			}
			if (this.checkPermission("stock_write")) {
				items[0].items.push({
					label: "Supplier statistics",
					command: () => {
						this.$router.push("/statistics");
					},
				});
			}

			return items;
		},
	},
	methods: {
		setAuthentication() {
			this.$store.dispatch("setAuthentication", true);
		},
		toggle(menuid, event) {
			if (menuid == "left") {
				this.$refs.leftAdminmenu.toggle(event);
				this.$refs.leftMenu.toggle(event);
			} else {
				this.$refs.rightMenu.toggle(event);
			}
		},
	},
};
</script>

<style lang="scss">
.p-button,
.p-badge,
radio,
.p-radiobutton-icon {
	//background: #1079aa !important;
	//color: white !important;
	font-size: inherit;
}
.p-button-link {
	background: transparent !important;
	color: #1079aa !important;
}
.router-link-exact-active {
	color: white !important;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//text-align: center;
	color: #2c3e50;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.nav {
	background-color: #0e212e;
	height: 50px;
	color: white;
	padding: 0.5rem 1rem;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__right {
		min-width: 180px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.p-submenu-icon {
			display: none;
		}
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__logo {
		width: 57px;
		height: 44px;
		padding: 0 20px;
		box-sizing: content-box;
	}

	&__link-icon {
		margin-right: 5px;
	}

	a {
		font-weight: bold;
		color: white;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.p-datatable.p-datatable-sm .p-datatable-header {
	text-align: left;
	padding: 20px 0 !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: rgba(255, 255, 225, 0.1);
}
.p-paginator {
	justify-content: flex-start !important;
}
.p-menu .p-menuitem-link .p-menuitem-text {
	text-align: left;
}
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #e4e4e4 !important;
}
.p-menuitem-link {
	padding: 0.6rem 1rem !important;
}
/*
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
	color: white !important;
}
.p-menubar {
	border: none !important;
}
.p-menubar .p-menuitem-link {
	text-align: left !important;
}
*/

.p-submenu-list {
	width: 340px !important;
	max-width: 95vw !important;
}
</style>
