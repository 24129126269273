<template>
	<div class="account">
		<div class="p-fluid p-formgrid p-grid flex p-ai-center">
			<div class="p-field p-col-3">Name</div>
			<div class="p-field p-col-3">
				<InputText id="firstname" type="text" placeholder="First name" />
			</div>
			<div class="p-field p-col-3">
				<InputText id="prefix" type="text" placeholder="Prefix" />
			</div>
			<div class="p-field p-col-3">
				<InputText id="lastname" type="text" placeholder="Last name" />
			</div>
		</div>
		<div class="p-fluid p-formgrid p-grid flex p-ai-center">
			<div class="p-field p-col-3">Email</div>
			<div class="p-field p-col-9">
				<InputText id="email" type="text" placeholder="Email" />
			</div>
		</div>

		<div class="p-fluid p-formgrid p-grid flex p-ai-center">
			<div class="p-field p-col-9 p-offset-3">Fill in password only if you wan't to change your current password.</div>
		</div>
		<div class="p-fluid p-formgrid p-grid flex p-ai-center">
			<div class="p-field p-col-3">Password</div>
			<div class="p-field p-col-3">
				<InputText id="password" type="text" placeholder="Password" />
			</div>
			<div class="p-field p-col-2 p-offset-1">Repeat password</div>
			<div class="p-field p-col-3">
				<InputText id="repeatpassword" type="text" placeholder="Password" />
			</div>
		</div>
		<div class="p-fluid p-formgrid p-grid flex p-ai-center">
			<div class="p-field p-col-2 p-offset-3">
				<Button label="Save" />
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
.account {
	margin-top: 50px;
	margin-right: 60px;
	margin-left: 60px;
	text-align: left;
}
</style>
