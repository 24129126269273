<template>
	<div v-if="clientOrder && clientOrder.number">
		<vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="false" :preview-modal="false" :paginate-elements-by-height="1400" :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="clientOrder2Pdf" @beforeDownload="beforePDFDownload($event)">
			<section slot="pdf-content">
				<ClientOrderDetails :clientOrder="clientOrder" :clientOrderLines="clientOrder.orderlines"></ClientOrderDetails>
			</section>
		</vue-html2pdf>
	</div>
</template>

<script>
import ClientOrderDetails from "@/components/ClientOrderDetails.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
	components: { ClientOrderDetails, VueHtml2pdf },
	props: {
		clientOrder: Object,
		clientOrderLines: Array,
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		async printClientOrder() {
			await this.$refs.clientOrder2Pdf.generatePdf();
			//this.$emit("close");
		},
		async beforePDFDownload({ html2pdf, options, pdfContent }) {
			let self = this;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(10);
						pdf.setTextColor(150);
						pdf.text("Page " + i + " of " + totalPages, pdf.internal.pageSize.getWidth() * 0.88, pdf.internal.pageSize.getHeight() - 0.3);
					}
					let pdfOutputBlob = pdf.output("blob");
					let pdfUrl = URL.createObjectURL(pdfOutputBlob);

					// Create a download link and add it to the DOM
					const downloadLink = document.createElement("a");
					downloadLink.href = pdfUrl;
					downloadLink.download = self.clientOrder.number + ".pdf";
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);
					URL.revokeObjectURL(downloadLink.href);
				});
		},
	},
	watch: {},
	mounted() {
		this.pdfGenerationKey++;
	},
};
</script>
