<template>
	<div class="login__wrapper">
		<img src="../assets/logo.png" alt="PPG logo" class="login__logo" />
		<div class="login boxshadow" v-if="token">
			<div class="login__title"><i class="login__title--icon pi pi-refresh"></i>RESET PASSWORD</div>

			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-lock"></i>
				</span>
				<InputText id="Password" type="password" v-model="password" placeholder="Password" />
			</div>

			<div class="p-inputgroup login__input">
				<span class="p-inputgroup-addon">
					<i class="pi pi-lock"></i>
				</span>
				<InputText id="Password2" type="password" v-model="password2" placeholder="Repeat" />
			</div>
			<div class="login__buttons">
				<Button class="login__button" label="Reset" @click="passwordReset" />
			</div>
		</div>
		<Toast />
	</div>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			password: "",
			password2: "",
			query: this.$route && this.$route.query && this.$route.query.query ? this.$route.query.query : null,
		};
	},
	computed: {
		email() {
			if (this.query && this.query.indexOf("?") > 0) {
				let query_array = this.query.split("?");
				if (query_array && query_array.length > 1) {
					return query_array[0];
				}
			}
			return null;
		},
		token() {
			if (this.query && this.query.indexOf("?") > 0) {
				let query_array = this.query.split("?");
				if (query_array && query_array.length > 1) {
					let token = query_array[1].split("=")[1];
					if (token && token.length > 0) {
						return token;
					}
				}
			}
			return null;
		},
	},
	methods: {
		setMode(mode) {
			this.mode = mode;
		},

		async passwordReset() {
			if (!this.password) {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Please enter your password.",
					life: 5000,
				});
				return;
			}
			if (this.password && this.password !== this.password2) {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Passwords do not match.",
					life: 5000,
				});
				return;
			}

			let response = await this.$store.dispatch("passwordReset", { email: this.email, token: this.token, password: this.password });

			if (response && response == "success") {
				this.$toast.add({
					severity: "success",
					summary: "Reset!",
					detail: "Your password is reset successfully.",
					life: 5000,
				});
				this.$store.dispatch("login", { email: this.email, password: this.password });
			} else {
				this.$toast.add({
					severity: "error",
					summary: "Error!",
					detail: "Something went wrong.",
					life: 5000,
				});
			}
		},
	},
};
</script>

<style lang="scss">
.login__wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login {
	background: white;
	text-align: left;
	padding: 20px;
	border-radius: 8px;
	width: 500px;
	margin: 20px;

	&__title {
		margin: 10px 0 40px;
		font-size: 20px;
		font-weight: bold;

		&--icon {
			margin-right: 10px;
		}
	}

	&__input {
		margin-bottom: 10px;
		width: 100%;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
	}

	&__button {
		margin-bottom: 10px;
	}

	&__link {
		display: block;
		margin-top: 20px;

		&:focus {
			box-shadow: none !important;
		}
	}

	&__logo {
		position: absolute;
		left: 20px;
		top: 30px;
		height: 50px;
	}
}
</style>
