import { Directus } from "@directus/sdk";
const directus = new Directus("https://ppg-rawmaterials-api.brndcntrl.com/");
import jwtDecode from "jwt-decode";

const jwtTokenDecoded = function () {
	let token = null;

	if (directus && directus.auth && directus.auth.token) {
		token = directus.auth.token;
	}
	if (token) {
		const response = jwtDecode(token);
		const expiresIn = response.exp * 1000 - Date.now();
		return {
			valid: expiresIn > 0,
			response: response,
			expiresIn: expiresIn,
		};
	} else {
		return {
			valid: false,
			response: "no token",
			expiresIn: 0,
		};
	}
};

export default async function ({ $axios, route, next }) {
	window.directus = directus;

	let tokenDecoded = jwtTokenDecoded();

	if (!tokenDecoded.valid) {
		location.href = "/login/";
		return null;
	} else {
		return next();
	}
}
