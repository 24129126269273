<template>
	<div class="table">
		<div class="table__title">
			{{ title }}
		</div>

		<Dialog class="table__dialog" :visible.sync="showPurchasesDialog" :style="{ 'width': '95vw', 'maxWidth': '900px', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<div>
					<h3>
						Purchases <span class="font-light" v-if="purchasesActive && purchasesActive.length > 0 && purchasesActive[0].supplier">{{ purchasesActive[0].supplier.name }}</span>
					</h3>
				</div>
			</template>
			<div v-if="purchasesActive && purchasesActive.length > 0 && purchasesActive[0].supplier">
				<DataTable :value="purchasesActive" class="p-datatable-sm p-datatable-striped">
					<template #header>
						<div style="text-align: left; padding: 0px 10px 0px 10px">
							<download-excel class="p-button p-component" :data="purchasesActiveForExcel" :name="'purchases_' + purchasesActive[0].supplier.code + '_' + $moment().format('YYYY-DD-MM hh-mm-ss') + '.csv'" type="csv"> <i class="pi pi-file-excel"></i>&nbsp;Export (.csv) </download-excel>
						</div>
					</template>
					<Column field="supplier" header="Supplier name">
						<template #body="{ data }">
							<div v-if="data.supplier && data.supplier.name">
								{{ data.supplier.name }}
							</div>
						</template>
					</Column>
					<Column field="supplier" header="Supplier code">
						<template #body="{ data }">
							<div v-if="data.supplier && data.supplier.name">
								{{ data.supplier.code }}
							</div>
						</template>
					</Column>
					<Column field="supplier" header="Supplier GNL">
						<template #body="{ data }">
							<div v-if="data.supplier && data.supplier.name">
								{{ data.supplier.gnl }}
							</div>
						</template>
					</Column>
					<Column field="number" header="Number"> </Column>
					<Column field="date" header="Date" key="date">
						<template #body="{ data }">
							<div v-if="data.date">
								{{ data.date | moment("DD MMMM YYYY") }}
							</div>
						</template>
					</Column>
					<Column field="label" header="Delivery" key="label">
						<template #body="{ data }">
							<div v-if="data.label">
								{{ data.label }}
							</div>
						</template>
					</Column>
					<Column field="label" header="Comments" key="label">
						<template #body="{ data }">
							<div v-if="data.comments">{{ data.comments }}</div>
						</template>
					</Column>
				</DataTable>
			</div>
		</Dialog>

		<DataTable :paginator="true" :rows="20" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 20, 50]" class="p-datatable-sm p-datatable-striped" :value="suppliersData" sortMode="single" :sortOrder="1" showGridlines ref="dt">
			<template #header>
				<div style="text-align: left; padding: 0px 10px 0px 10px">
					<download-excel class="p-button p-component" :data="suppliersDataForExcel" :name="'statistics_' + $moment().format('YYYY-DD-MM hh-mm-ss') + '.csv'" type="csv"> <i class="pi pi-file-excel"></i>&nbsp;Export (.csv) </download-excel>
				</div>
			</template>
			<Column field="select" header="Select" key="select" sortable>
				<template #body>
					<div class="p-field-checkbox mt-3">
						<Checkbox id="binary" :binary="true" />
					</div>
				</template>
			</Column>
			<Column field="name" header="Supplier" key="name"> </Column>
			<Column field="code" header="Code" key="code"> </Column>
			<Column field="gnl" header="GNL" key="gnl">
				<template #body="{ data }">
					{{ data.gnl }}
				</template>
			</Column>
			<Column field="purchases" header="Purchases" key="purchases">
				<template #body="{ data }">
					{{ data.purchases.length }}
				</template>
			</Column>
			<Column field="purchasesComplete" header="Complete" key="purchasesComplete">
				<template #body="{ data }">
					{{ data.purchasesComplete.length }}
				</template>
			</Column>
			<Column field="purchasesIncomplete" header="Incomplete" key="purchasesIncomplete">
				<template #body="{ data }">
					<span class="p-badge p-component p-badge-warning">{{ data.purchasesIncomplete.length }}</span>
				</template>
			</Column>
			<Column field="purchasesInTime" header="In time" key="purchasesInTime">
				<template #body="{ data }">
					<span class="p-badge p-component p-badge-success">{{ data.purchasesInTime.length }}</span>
				</template>
			</Column>
			<Column field="purchasesToLate" header="Too late" key="purchasesToLate">
				<template #body="{ data }">
					<span class="p-badge p-component p-badge-danger" @click="showPurchases(data.purchasesToLate)">{{ data.purchasesToLate.length }}</span>
				</template>
			</Column>
			<Column field="Actions" header=" " key="actions">
				<template #body="{ data }">
					<div class="flex p-ai-center">
						<Button type="button" icon="pi pi-align-justify" class="p-button-rounded mr-2" v-tooltip.top="'Show orders'" @click="showPurchases(data)" />
					</div>
				</template>
			</Column>

			<template #footer>
				<div class="table__footer"><strong>For questions please contact:</strong> RDA Warehouse | <strong>E:</strong> <a href="mailto:SR0734@ppg.com"> SR0734@ppg.com</a> |<strong> P:</strong> +31 20 407 53 12</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			title: "Supplier statistics",
			showPurchasesDialog: false,
			purchasesActive: null,
		};
	},
	async mounted() {
		await this.getData();
	},
	methods: {
		getData() {
			this.$store.dispatch("getData");
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		showPurchases(supplier) {
			let self = this;
			let purchases = [];
			if (supplier && supplier.purchasesIncomplete && supplier.purchasesIncomplete.length > 0) {
				purchases = _.concat(
					purchases,
					supplier.purchasesIncomplete.map((value) => {
						value.label = "Incomplete";
						return value;
					})
				);
			}
			if (supplier && supplier.purchasesInTime && supplier.purchasesInTime.length > 0) {
				purchases = _.concat(
					purchases,
					supplier.purchasesInTime.map((value) => {
						value.label = "In time";
						return value;
					})
				);
			}
			if (supplier && supplier.purchasesToLate && supplier.purchasesToLate.length > 0) {
				purchases = _.concat(
					purchases,
					supplier.purchasesToLate.map((value) => {
						value.label = "Too late";
						return value;
					})
				);
			}

			self.purchasesActive = purchases;
			self.showPurchasesDialog = true;
		},
	},
	computed: {
		...mapState({
			orders: (state) => state.orders,
			orderlines: (state) => state.orderlines,
		}),
		suppliersData() {
			let self = this;
			let suppliersData = self.$store.state.data.suppliers;
			let purchases = self.$store.state.data.purchases;
			if (suppliersData && suppliersData.length > 0) {
				suppliersData.forEach((supplier, index) => {
					suppliersData[index].purchases = [];
					suppliersData[index].purchasesComplete = [];
					suppliersData[index].purchasesIncomplete = [];
					suppliersData[index].purchasesInTime = [];
					suppliersData[index].purchasesToLate = [];
					if (purchases && purchases.length > 0) {
						let purchasesFromSupplier = _.filter(purchases, function (purchase) {
							return purchase.supplier.id == supplier.id;
						});
						suppliersData[index].purchases = purchasesFromSupplier;
						if (purchasesFromSupplier.length > 0) {
							let purchasesComplete = _.filter(purchasesFromSupplier, function (purchase) {
								if (purchase.quantity && purchase.quantity_received && purchase.quantity <= purchase.quantity_received) {
									return true;
								}
								return false;
							});
							let purchasesIncomplete = _.filter(purchasesFromSupplier, function (purchase) {
								if (!purchase.quantity_received || purchase.quantity > purchase.quantity_received) {
									return true;
								}
								return false;
							});
							suppliersData[index].purchasesComplete = purchasesComplete;
							suppliersData[index].purchasesIncomplete = purchasesIncomplete;

							let purchasesInTime = [];
							let purchasesToLate = [];
							if (purchasesComplete && purchasesComplete.length > 0) {
								purchasesComplete.forEach((purchase) => {
									if (purchase.date && purchase.date_received && supplier.delivery_days) {
										let date = self.$moment(purchase.date).format("YYYY-MM-DD");
										let date_todeliver = self.$moment(date).add(7, "days").format("YYYY-MM-DD");
										let date_received = self.$moment(purchase.date_received).format("YYYY-MM-DD");
										let diff = self.$moment(date_received).diff(date_todeliver, "days");
										console.log(date_todeliver, date_received, diff);
										if (diff <= supplier.delivery_days) {
											purchasesInTime.push(purchase);
										}
										if (diff > supplier.delivery_days) {
											purchasesToLate.push(purchase);
										}
									}
								});
							}
							suppliersData[index].purchasesInTime = purchasesInTime;
							suppliersData[index].purchasesToLate = purchasesToLate;
						}
					}
					console.log("sdfgd");
				});
				return suppliersData;
			}
			return null;
		},
		suppliersDataForExcel() {
			let self = this;
			let suppliersDataForExcel = [];
			if (self.suppliersData) {
				self.suppliersData.forEach((row) => {
					suppliersDataForExcel.push({
						name: row.name,
						code: row.code,
						gnl: row.gnl,
						purchases: row.purchases.length ? row.purchases.length : 0,
						purchasesComplete: row.purchasesComplete.length ? row.purchasesComplete.length : 0,
						purchasesIncomplete: row.purchasesIncomplete.length ? row.purchasesIncomplete.length : 0,
						purchasesInTime: row.purchasesInTime.length ? row.purchasesInTime.length : 0,
						purchasesToLate: row.purchasesToLate.length ? row.purchasesToLate.length : 0,
					});
				});
			}
			return suppliersDataForExcel;
		},
		purchasesActiveForExcel() {
			let self = this;
			let purchasesActiveForExcel = [];
			if (self.purchasesActive) {
				self.purchasesActive.forEach((row) => {
					purchasesActiveForExcel.push({
						supplier_name: row.supplier && row.supplier.name ? row.supplier.name : "",
						supplier_code: row.supplier && row.supplier.code ? row.supplier.code : "",
						supplier_gnl: row.supplier && row.supplier.gnl ? row.supplier.gnl : "",
						number: row.number ? row.number : "",
						date: row.date ? self.$moment(row.date).format("YYYY-MM-DD hh:mm:ss") : "",
						delivery: row.label ? row.label : "",
						comments: row.comments ? row.comments : "",
					});
				});
			}
			return purchasesActiveForExcel;
		},
	},
};
</script>

<style lang="scss">
.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__footer {
		text-align: left;
	}
}
</style>
