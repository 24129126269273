<template>
	<div id="app">
		<component :is="this.$route.meta.layout"></component>
	</div>
</template>

<script>
export default {
	name: "App",
	components: {},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: "PPG Raw Materials",
		// all titles will be injected into this template
		titleTemplate: "PPG Raw Materials",
	},

	data() {
		return {
			layout: null,
		};
	},
	mounted() {
		//this.$toast.add({severity:'success', summary: 'Success Message', detail:'Order submitted', life: 3000});
	},
	computed: {},
	methods: {},
};
</script>

<style lang="scss">
html,
body,
td,
th,
.p-component {
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 16px;
}

html {
	height: 100%;
}
body {
	margin: 0;
	height: 100%;
	width: 100%;
	background: rgb(223, 240, 249);
	background: linear-gradient(circle, rgba(223, 240, 249, 0.5) 0%, rgba(223, 240, 249, 0.5) 54%, rgba(96, 172, 208, 0.5) 100%);
	//font-size: 14px !important;
}

.boxshadow {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
.p-badge {
	white-space: nowrap;
}
.error {
	font-size: 0.8em;
	color: #cc0000;
	padding: 3px 0 0 0;
}
</style>
