import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Menu from "primevue/menu";
import MenuBar from "primevue/menubar";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import CheckBox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Badge from "primevue/badge";
import RadioButton from "primevue/radiobutton";
import TextArea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import BadgeDirective from "primevue/badgedirective";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import axios from "axios";
import VueMeta from "vue-meta";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://ppg-rawmaterials-api.brndcntrl.com/";

Vue.prototype.$axios = axios;

import { Directus } from "@directus/sdk";
const directus = new Directus("https://ppg-rawmaterials-api.brndcntrl.com/");
Vue.prototype.$directus = directus;

const moment = require("moment");
require("moment/locale/nl");

Vue.use(require("vue-moment"), {
	moment,
});

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import { required, email, alpha_num } from "vee-validate/dist/rules";

import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";

localize({
	en,
	nl,
});

localize("en");

extend("required", required);
extend("email", email);
extend("alpha_num", alpha_num);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.mixin({
	methods: {
		checkPermission: function (permission) {
			if (this.$store.state.user && this.$store.state.user.role && this.$store.state.user.role.permissions && permission) {
				if (this.$store.state.user.role.permissions.includes(permission)) {
					return true;
				}
			}
			return false;
		},
	},
});

import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
Vue.use(ConfirmationService);

Vue.use(PrimeVue);
Vue.use(VueMeta);
Vue.use(ToastService);
Vue.component("Toast", Toast);
Vue.component("Checkbox", CheckBox);
Vue.component("Dropdown", Dropdown);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("ColumnGroup", ColumnGroup);
Vue.component("Menu", Menu);
Vue.component("MenuBar", MenuBar);
Vue.component("Button", Button);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("Chip", Chip);
Vue.component("Divider", Divider);
Vue.component("Dialog", Dialog);
Vue.component("Badge", Badge);
Vue.component("RadioButton", RadioButton);
Vue.component("TextArea", TextArea);
Vue.component("Calendar", Calendar);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.directive("tooltip", Tooltip);
Vue.directive("badge", BadgeDirective);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
