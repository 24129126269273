import Vue from "vue";
import VueRouter from "vue-router";

import directus_auth from "../middleware/directus_auth.js";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import LayoutLogin from "@/layouts/LayoutLogin.vue";

import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";

import Cart from "../views/Cart.vue";

import RawMaterials from "../views/RawMaterials.vue";
import RawMaterialsNonStock from "../views/RawMaterialsNonStock.vue";
import ClientOrders from "../views/ClientOrders.vue";
import OrdersOverview from "../views/OrdersOverview.vue";
import SupplierOrders from "../views/SupplierOrders.vue";
import RawMaterialsCriticalStock from "../views/RawMaterialsCriticalStock.vue";
import Statistics from "../views/Statistics.vue";

import Account from "../views/Account.vue";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			layout: LayoutLogin, // we add new meta layout here to use it later
		},
	},
	{
		path: "/resetpassword",
		name: "ResetPassword",
		component: ResetPassword,
		meta: {
			layout: LayoutLogin, // we add new meta layout here to use it later
		},
	},
	{
		path: "/cart",
		name: "Cart",
		component: Cart,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/",
		name: "RawMaterials",
		component: RawMaterials,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/rawmaterialsnonstock",
		name: "RawMaterialsNonStock",
		component: RawMaterialsNonStock,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/clientorders",
		name: "ClientOrders",
		component: ClientOrders,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/orders",
		name: "OrdersOverview",
		component: OrdersOverview,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/supplierorders",
		name: "SuppliersOrders",
		component: SupplierOrders,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/criticalstock",
		name: "CriticalStock",
		component: RawMaterialsCriticalStock,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/statistics",
		name: "Statistics",
		component: Statistics,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
	{
		path: "/account",
		name: "Account",
		component: Account,
		meta: {
			middleware: directus_auth,
			layout: LayoutDefault,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next;

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters);
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

router.beforeEach(async (to, from, next) => {
	//console.log("store", store);
	await store.restored;

	//if (to.name !== "Login" && !store.state.isAuthenticated) next({ name: "Login" });
	//else next();
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

		const context = {
			from,
			next,
			router,
			to,
		};
		const nextMiddleware = nextFactory(context, middleware, 1);
		return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export default router;
