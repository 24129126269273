<template>
	<Dialog class="table__dialog" :visible.sync="showLocal" :style="{ 'width': '95vw', 'maxWidth': '600px', 'line-height': '30px' }" position="top" :modal="true">
		<template #header>
			<h3 class="mb-0">Purchase from Supplier</h3>
		</template>
		<div>
			<div v-if="rawmaterial">
				<h3 class="mb-2">Raw material</h3>
				<div>{{ rawmaterial.sapcode }} - {{ rawmaterial.name }}<br />{{ rawmaterial.packaging }}</div>
				<div v-if="rawmaterial.stockSum || rawmaterial.stockSum === 0">On stock: {{ rawmaterial.stockSum }}</div>
			</div>
			<div v-else>
				<h3 class="mb-2">{{ orderline.sapcode }} - {{ orderline.name }}<br />{{ orderline.packaging }}</h3>
			</div>

			<div v-if="orderline">
				<h3 class="mb-2">This order</h3>
				<div>{{ orderline.date_created | moment("DD MMMM YYYY") }}</div>
				<div>By: {{ orderline.user_created.first_name }} {{ orderline.user_created.last_name }} ({{ orderline.user_created.email }})</div>
				<div>Quantity: {{ orderline.quantity }}, Already delivered: {{ orderline.deliveriesSum }}, Remaining: {{ orderline.toDeliver }}</div>
				<div v-if="rawmaterial">
					<div v-if="orderline.stockSum || orderline.stockSum === 0">On stock: {{ orderline.stockSum }}</div>
				</div>
			</div>

			<hr class="mb-4" />
			<div class="flex p-ai-center">
				Quantity:
				<InputNumber v-model="quantity" mode="decimal" class="ml-2" style="width: 4rem" />
				<Button icon="pi pi-shopping-cart" type="button" @click="purchaseOrderline(rawmaterial, orderline, quantity)" class="ml-2" label="Purchase at supplier" :disabled="!quantity || quantity < 1" />
			</div>
		</div>
		<div></div>
	</Dialog>
</template>

<script>
export default {
	components: {},
	props: {
		orderline: Object,
		rawmaterial: Object,
		show: Boolean,
	},
	data() {
		return {
			quantity: 0,
			visible: true,
		};
	},
	computed: {
		showLocal: {
			get: function () {
				return this.show;
			},
			set: function (value) {
				this.$emit("hide");
			},
		},
	},
	methods: {
		async purchaseOrderline(rawmaterial, orderline, quantity) {
			let self = this;

			let response = await this.$store.dispatch("purchaseOrderline", {
				quantity: quantity,
				rawmaterial: rawmaterial,
				orderline: orderline,
				costplace: orderline && orderline.order && orderline.order.costplace ? orderline.order.costplace : "",
			});

			console.log("response", response);

			if (response && response.number) {
				if (orderline && orderline.supplier && orderline.supplier.email) {
					orderline.urgent = orderline.urgent ? "Yes" : "No";

					let delivery_days = orderline.supplier.deliverydays || 0;
					let order_date = response.date;
					let delivery_date = self.$moment(order_date).add(delivery_days, "days");

					if (response.date_created) {
						response.date_created = self.$moment(response.date_created).format("DD-MM-YYYY");
					}

					let email_to = [{ Email: orderline.supplier.email }];

					let email_subject = "PPG Raw Materials - Purchase order " + response.number;

					let email_variables = {
						orderline: orderline,
						supplier: orderline.supplier,
						purchaseorder: response,
						order_date: self.$moment(order_date).format("DD-MM-YYYY"),
						delivery_date: self.$moment(delivery_date).format("DD-MM-YYYY"),
					};

					console.log(email_variables);

					if (self.$directus && self.$directus.auth && self.$directus.auth.token) {
						self.$axios.post(
							self.$store.state.DIRECTUS_URL + "mail/purchaseorder",
							{
								to: [{ Email: "AMS-MB.R&DWarehouse@ppg.com" }],
								bcc: [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }],
								subject: email_subject,
								variables: email_variables,
							},
							{
								headers: {
									Authorization: "Bearer " + self.$directus.auth.token,
								},
							}
						);
					}
				}
			}

			this.$toast.add({
				severity: "success",
				summary: "Purchase!",
				detail: "Purchase order placed!",
				life: 3000,
			});
			this.$store.dispatch("getData");
			this.showLocal = false;
		},
	},
	watch: {
		showLocal(newval) {
			if (newval == true) {
				this.quantity = 0;
			}
		},
	},
	mounted() {},
};
</script>
