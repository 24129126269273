<template>
	<div style="height: 100vh; width: 100vw; background-size: cover; background-repeat: no-repeat" :style="{ backgroundImage: 'url(' + require('@/assets/background-login.jpg') + ')' }">
		<router-view />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "LayoutDefault",

	data() {
		return {};
	},
	mounted() {
		//this.$toast.add({severity:'success', summary: 'Success Message', detail:'Order submitted', life: 3000});
	},
	computed: {},
	methods: {
		setAuthentication() {
			this.$store.dispatch("setAuthentication", true);
		},
	},
};
</script>

<style lang="scss">
.p-button,
.p-badge,
radio,
.p-radiobutton-icon {
	//background: #1079aa !important;
	//color: white !important;
	font-size: inherit;
}
.p-button-link {
	background: transparent !important;
	color: #1079aa !important;
}
.router-link-exact-active {
	color: white !important;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//text-align: center;
	color: #2c3e50;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.nav {
	background-color: #0e212e;
	height: 50px;
	color: white;
	padding: 0.5rem 1rem;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__right {
		min-width: 180px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.p-submenu-icon {
			display: none;
		}
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__logo {
		width: 57px;
		height: 44px;
		padding: 0 20px;
		box-sizing: content-box;
	}

	&__link-icon {
		margin-right: 5px;
	}

	a {
		font-weight: bold;
		color: white;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.p-datatable.p-datatable-sm .p-datatable-header {
	text-align: left;
	padding: 20px 0 !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: rgba(255, 255, 225, 0.1);
}
.p-paginator {
	justify-content: flex-start !important;
}
.p-menu .p-menuitem-link .p-menuitem-text {
	text-align: left;
}
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #e4e4e4 !important;
}
.p-menuitem-link {
	padding: 0.6rem 1rem !important;
}
/*
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
	color: white !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
	color: white !important;
}
.p-menubar {
	border: none !important;
}
.p-menubar .p-menuitem-link {
	text-align: left !important;
}
*/

.p-submenu-list {
	width: 340px !important;
	max-width: 95vw !important;
}
</style>
